define("discourse/plugins/discourse-gamification/admin/components/admin-create-leaderboard", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/object/computed", "@ember/service", "discourse/components/form", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _computed, _service, _form, _ajax, _ajaxError, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminCreateLeaderboard extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "toasts", [_service.service]))();
    #toasts = (() => (dt7948.i(this, "toasts"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "newLeaderboardName", [_tracking.tracked], function () {
      return "";
    }))();
    #newLeaderboardName = (() => (dt7948.i(this, "newLeaderboardName"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "nameValid", [(0, _computed.readOnly)("newLeaderboardName")]))();
    #nameValid = (() => (dt7948.i(this, "nameValid"), void 0))();
    get formData() {
      return {
        name: "",
        created_by_id: this.currentUser.id
      };
    }
    async createNewLeaderboard(data) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        const leaderboard = await (0, _ajax.ajax)("/admin/plugins/gamification/leaderboard", {
          data,
          type: "POST"
        });
        this.toasts.success({
          duration: 3000,
          data: {
            message: (0, _i18n.default)("gamification.leaderboard.create_success")
          }
        });
        this.args.onCancel();
        this.router.transitionTo("adminPlugins.show.discourse-gamification-leaderboards.show", leaderboard.id);
      } catch (err) {
        (0, _ajaxError.popupAjaxError)(err);
      } finally {
        this.loading = false;
      }
    }
    static #_7 = (() => dt7948.n(this.prototype, "createNewLeaderboard", [_object.action]))();
    static #_8 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="new-leaderboard-container">
          <Form
            @data={{this.formData}}
            @onSubmit={{this.createNewLeaderboard}}
            class="new-leaderboard-form"
            as |form|
          >
            <form.Row>
              <form.Field
                @name="name"
                @title={{i18n "gamification.leaderboard.name"}}
                @showTitle={{false}}
                class="new-leaderboard__name"
                @validation="required"
                as |field|
              >
                <field.Input
                  placeholder={{i18n "gamification.leaderboard.name_placeholder"}}
                />
              </form.Field>
    
            </form.Row>
            <form.Actions>
              <form.Submit />
    
              <form.Button
                @action={{@onCancel}}
                class="new-leaderboard__cancel form-kit__button"
                @label="gamification.cancel"
                @title="gamification.cancel"
              />
            </form.Actions>
          </Form>
        </div>
      
    */
    {
      "id": "S0Uv6vgM",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"new-leaderboard-container\"],[12],[1,\"\\n      \"],[8,[32,0],[[24,0,\"new-leaderboard-form\"]],[[\"@data\",\"@onSubmit\"],[[30,0,[\"formData\"]],[30,0,[\"createNewLeaderboard\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1,[\"Row\"]],null,null,[[\"default\"],[[[[1,\"\\n          \"],[8,[30,1,[\"Field\"]],[[24,0,\"new-leaderboard__name\"]],[[\"@name\",\"@title\",\"@showTitle\",\"@validation\"],[\"name\",[28,[32,1],[\"gamification.leaderboard.name\"],null],false,\"required\"]],[[\"default\"],[[[[1,\"\\n            \"],[8,[30,2,[\"Input\"]],[[16,\"placeholder\",[28,[32,1],[\"gamification.leaderboard.name_placeholder\"],null]]],null,null],[1,\"\\n          \"]],[2]]]]],[1,\"\\n\\n        \"]],[]]]]],[1,\"\\n        \"],[8,[30,1,[\"Actions\"]],null,null,[[\"default\"],[[[[1,\"\\n          \"],[8,[30,1,[\"Submit\"]],null,null,null],[1,\"\\n\\n          \"],[8,[30,1,[\"Button\"]],[[24,0,\"new-leaderboard__cancel form-kit__button\"]],[[\"@action\",\"@label\",\"@title\"],[[30,3],\"gamification.cancel\",\"gamification.cancel\"]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[1]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"form\",\"field\",\"@onCancel\"],false,[]]",
      "moduleName": "/srv/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-gamification/admin/components/admin-create-leaderboard.js",
      "scope": () => [_form.default, _i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AdminCreateLeaderboard;
});